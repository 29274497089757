import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import { SunriseContextProvider } from "./data/context";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import App from "./App";
import Avatar from "./pages/avatar";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <SunriseContextProvider>
      <Router>
        <Routes>
          <Route path="/:avatarKey" element={<App />} />
          <Route path="/avatar" element={<Avatar />} />
        </Routes>
      </Router>
    </SunriseContextProvider>
  </React.StrictMode>
);
