import axios from "axios";

axios.defaults.baseURL = "https://api.campusxr.org/";
// axios.defaults.baseURL = "http://localhost:8001/";
axios.defaults.headers = { "Content-Type": "application/json" };

async function getavatar(avatarKey) {
  try {
    const response = await axios.get(`/dialogue/${avatarKey}`);
    console.log(response);
    return response;
  } catch (error) {
    console.error(error);
  }
}

async function getResponse(data) {
  try {
    const response = await axios.post(`/dialogue/dialogueFlow`, data);
    return response.data;
  } catch (error) {
    console.error(error);
  }
}

export { getavatar, getResponse };
