import React from "react";
import "./loader.css";
const Loader = () => {
  return (
    <div className=" grid place-content-center min-h-screen w-screen">
      <div className="loader"></div>
    </div>
  );
};

export default Loader;
