import { createContext, useContext, useReducer, useState } from "react";
import { avatarReducer, initialState } from "./store";

const SunriseContext = createContext();

export const useSunriseContext = () => useContext(SunriseContext);

export const SunriseContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(avatarReducer, initialState);
  const [loader, setLoader] = useState(true);

  const value = {
    state,
    dispatch,
    loader,
    setLoader,
  };

  return (
    <SunriseContext.Provider value={value}>{children}</SunriseContext.Provider>
  );
};
