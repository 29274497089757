export const initialState = {
  avatar: {
    characterName: "",
    id: "",
    idleEndTime: 0,
    idleStartTime: 0,
    key: "",
    logo: "",
    movementStartTime: "",
    privateKey: "",
    projectId: "",
    clientEmail: "",
    animation: "",
    text: "",
    video: "",
    createdAt: "",
    updatedAt: "",
  },
  loading: false,
  loaded: false,
  error: null,
};

export const avatarReducer = (state, action) => {
  switch (action.type) {
    case "SET_avatar": {
      return {
        ...state,
        avatar: action.payload,
        loaded: true,
      };
    }
    case "RESET_avatar": {
      return {
        ...initialState,
      };
    }
    default:
      return state;
  }
};
