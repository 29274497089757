import { Link } from "react-router-dom";
import "./App.css";
import { useSunriseContext } from "./data/context";
import Loader from "./components/loader";
import { UseAvatarKey } from "./utils/useAvtarKey";
import { useCallback, useEffect } from "react";
import { getavatar } from "./service/avatarService";
function App() {
  const { loader, setLoader } = useSunriseContext();
  const { state, dispatch } = useSunriseContext();
  const { avatar, loaded } = state;

  const avatarKey = UseAvatarKey();

  useEffect(() => {
    (async function gettingavatar() {
      const avatar = await getavatar(avatarKey);
    })();
  }, [avatarKey]);

  const gettingavatar = useCallback(async () => {
    try {
      const avatarData = await getavatar(avatarKey);
      if (avatarData) {
        dispatch({ type: "SET_avatar", payload: avatarData.data.data });
      }
    } catch (error) {
      console.log("Some error occurred");
    }
  }, [avatarKey]);

  useEffect(() => {
    if (loaded) return;
    gettingavatar();
  }, [gettingavatar]);

  if (!loaded) return <Loader />;
  else
    return (
      <div
        className=" min-h-screen w-full grid place-content-center"
        style={{ backgroundColor: `${avatar.characterColor}` }}
      >
        <div className="max-w-xs flex flex-col justify-center gap-3 shadow-lg bg-slate-50 p-10 rounded-lg">
          <div className="rounded-lg">
            <img src={avatar.logo} className=" self-center" alt="Background" />
          </div>

          <div className="flex flex-col justify-center items-center gap-3">
            <h1
              className="text-lg font-bold"
              style={{ color: `${avatar.textColor}` }}
            >
              {avatar.characterName}
            </h1>

            {avatar.text && (
              <p
                className="text-base text-center"
                style={{ color: `${avatar.textColor}` }}
              >
                {avatar.text}
              </p>
            )}

            <Link
              className="bg-black p-3 rounded-md text-cente"
              to={`/avatar?key=${avatarKey}`}
              style={{ backgroundColor: `${avatar.characterColor}` }}
            >
              <span className="text-white uppercase font-bold w-auto">
                Start
              </span>
            </Link>
          </div>
        </div>
      </div>
    );
}

export default App;
