import { redirect, useLocation } from "react-router-dom";

export function UseAvatarKey() {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const lastSegment = location.pathname.substring(
    location.pathname.lastIndexOf("/") + 1
  );

  if (searchParams.has("key")) {
    return searchParams.get("key");
  } else {
    window.location.replace("https://www.campusxr.org/ai-representatives");
  }

  return lastSegment;
}
